<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <div
      class="mt-10"
      style="background:white; min-height:700px"
    >
      <v-row class="d-flex justify-center">
        <v-col
          cols="4"
          class="ma-5 d-flex"
          style="background:#B6EDFF; border-radius:30px"
        >
          <v-col
            cols="6"
            class="pa-0"
          >
            <span class="mr-5 blue--text">{{ $t('messages.manageId') }}</span>
            {{ customerDetail.code }}
          </v-col>
          <v-col
            cols="6"
            class="pa-0"
          >
            <span class="mr-5 blue--text">{{ $t('messages.factoryName') }}</span>
            {{ customerDetail.name }}
          </v-col>
        </v-col>
      </v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1 "
          :loading-text="$t('table.messages.loading')"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 30, 50],
            showFirstLastPage: false,
            'items-per-page-text': $t('table.messages.items_per_page'),
          }"
          @click:row="handleClick"
        >
          <template slot="no-data">
            {{ $t('table.messages.no_data') }}
          </template>
          <template
            v-slot:[`footer.page-text`]=""
            class="mr-0"
          >
            {{ $t('table.messages.page') }}
            {{ options.page }}
          </template>
          <template v-slot:item.index="props">
            {{ items.indexOf(props.item) + 1 }}
          </template>
          <template v-slot:[`footer.prepend`] />
        </v-data-table>
      </v-col>
      <v-snackbar v-model="snackbar">
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            {{ $t('messages.close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'

  export default {
    name: 'SalaryManagementList',
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        startAt: '',
        endAt: '',
        menuStart: false,
        menuEnd: false,
        options: {},
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'center',
            value: 'index',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.dateRange'),
            align: 'center',
            value: 'dateRange',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.methodManage'),
            align: 'center',
            value: 'methodManage',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.updatedTime'),
            align: 'center',
            value: 'updatedAt',
            width: '10em',
            class: 'font-weight-bold',
            sortable: false,
          },
        ],
        methodsList: [
          {
            text: this.$t('combobox.manage_by_factory'),
            value: 0,
          },
          {
            text: this.$t('combobox.manage_by_job'),
            value: 1,
          },
        ],
        method: '',
        editedIndex: -1,
        items: [],
        loading: false,
        filters: {
          startAt: '',
          endAt: '',
          type: '',
        },
      }
    },
    computed: {
      ...get('customer', ['customerDetail']),
      ...get('salary', ['listSalary', 'message', 'status', 'error']),
      itemsWithIndex () {
        return this.items.map(
          (items, index) => ({
            ...items,
            index: index + 1,
          }))
      },
      getMinDate () {
        const startAt = this.startAt
        return startAt
      },
      getMaxDate () {
        const endAt = this.endAt
        return endAt
      },
    },
    watch: {
      status (value) {
        this.loading = value === 'loading'
      },
      error (value) {
        this.showSnackBar(value)
      },
      listSalary (value) {
        value.map(val => {
          this.items.push({
            startAt: moment(val.startAt).format('YYYY-MM-DD'),
            endAt: moment(val.endAt).format('YYYY-MM-DD'),
            dateRange: moment(val.startAt).format('YYYY-MM-DD') + ' ~ ' + moment(val.endAt).format('YYYY-MM-DD'),
            type: val.type,
            methodManage: val.type === 0 ? this.$t('combobox.manage_by_factory') : this.$t('combobox.manage_by_job'),
            updatedAt: moment(val.createdAt).format('YYYY-MM-DD'),
          })
        })
      },
    },
    created () {
      const payload = {
        id: this.$route.params.id,
      }
      this.$store.dispatch('customer/getCustomer', {
        id: this.$route.params.id,
      })
      this.$store.dispatch('salary/getSalaryHistory', payload)
    },
    methods: {
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      handleClick (item) {
        const payload = {
          customerId: this.$route.params.id,
          startAt: item.startAt,
          endAt: item.endAt,
          type: item.type,
        }
        localStorage.setItem('dataSearch', JSON.stringify(payload))
        this.$router.push('/salary-management/' + this.$route.params.id)
      },
    },
  }
</script>
